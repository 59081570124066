import React, { useState } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';

export const PopupHome = ({ isOpen, toggle, text, show_command_button, command_button_text, command_button_link, new_tab }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle} style={{borderBottom:'0'}} ></ModalHeader>
            <ModalBody>
                <div dangerouslySetInnerHTML={{ __html: text }}></div>
                        {
                            show_command_button === 1 && command_button_text && command_button_link &&
                            <p className="mb-0 text-truncate d-flex align-items-end">
                                <a className="btn btn-outline-primary text-uppercase rounded-pill d-inline-flex px-md-3 mx-auto" href={command_button_link} target={`${new_tab ? '_blank' : ''}`} rel="noopener noreferrer" >{command_button_text}</a>
                            </p>
                        }
            </ModalBody>
        </Modal>
    )
}