import React, { useEffect } from 'react';
import { Col, Card, CardImg, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { questionnaireResumeActions } from '../../../../core/questionnaire-resume/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { Progress } from 'reactstrap';
import icon_search from '../../../../assets/img/search-community.svg'


export const NewMemberDiscovery = ({ oneColumn }) => {
    const dispatch = useDispatch();
    const { questionnaire_resume } = useSelector(state => state);
    const { user } = useSelector(state => state);


    useEffect(() => {
      dispatch(questionnaireResumeActions.loadQuestionnaireResume())
    }, []);


    return (
        <Col xl='12' className="mt-3">
            <Card className="p-4 pt-md-1 mb-4 mb-xl-0">
                <div className={`${oneColumn ? "px-md-5": ''}`}>
                    <div className="encabezado encabezado-icono">
                        <div className="mb-0 pt-2 pb-1 d-flex align-items-end">
                            <img src={user.account.questionnaires_icon} width="40" height="auto" alt="Thinking Talents" />
                            <h6 className="mb-0 ml-3 title-underline">{user.account.questionnaires_title}</h6>
                        </div>
                        <span className="border border-info d-none d-md-block"></span>
                    </div>
                    <div className="my-4 mt-md-2" dangerouslySetInnerHTML={{__html: user.account.questionnaire_presentation}}></div>


                    <Row className="d-flex justify-content-center justify-content-md-between flex-wrap questionnaire-resume-list">
                        {
                            questionnaire_resume.data.map((questionnaire) =>
                                <Col sm={`${oneColumn ? "4": '12'}`} key={questionnaire.id} className="text-center px-3 mb-3 mb-md-0">
                                    <div className="w-100">
                                        {!oneColumn && <hr/>}

                                        <p className={`text-petrol text-uppercase mb-0 ${oneColumn ? 'mt-3' : ''}`}>{questionnaire.name}</p>
                                        
                                        <div className="pt-1 mb-2">
                                            <Progress value={questionnaire.completion} barStyle={{backgroundColor: "#16B3BE"}} className={`${questionnaire.completion > 10 ? '' : 'questionnaire-cero'} questionnaireProgress overflow-initial`} barClassName="position-relative overflow-initial" >
                                                <div className="text-right text-black ml-n3">{questionnaire.completion}%<FontAwesomeIcon icon={faCaretDown} className="ml-1" size="lg" color="#C9C7C7"/></div>
                                            </Progress>
                                        </div>
                                    </div>
                                    
                                    {
                                        questionnaire.completion === 0 &&
                                        <Link to={`/questionnaire/${questionnaire.id}`} className="btn btn-outline-primary text-uppercase rounded-pill d-inline-flex px-md-4 mx-auto">Let’s start!</Link>
                                    }

                                    {
                                        questionnaire.completion > 0 && questionnaire.completion < 100 &&
                                        <Link to={`/questionnaire/${questionnaire.id}`} className="btn btn-outline-primary text-uppercase rounded-pill d-inline-flex px-md-4 mx-auto">Continue</Link>
                                    }

                                    {
                                        questionnaire.completion === 100 &&
                                        <Link to={`/questionnaire-review/${questionnaire.id}`} className="btn btn-outline-primary text-uppercase rounded-pill d-inline-flex px-md-4 mx-auto">Review answers</Link>
                                    }
                                </Col>

                            )
                        }
                    </Row>
                </div>
            </Card>
        </Col>
    )
}
