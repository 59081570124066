export const userActions = {

    FETCH_USER_FAILED: 'FETCH_USER_FAILED',
    FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
    FETCH_USER_PENDING: 'FETCH_USER_PENDING',
    LOAD_USER: 'LOAD_USER',
    GET_PROFILE: 'GET_PROFILE',
    LOGOUT: 'LOGOUT',
    SAVE_PATTERN: 'SAVE_PATTERN',
    CURRENT_USER: 'CURRENT_USER',
    UPDATE_USER: 'UPDATE_USER',
    NEXT_POPUP_INDEX: 'NEXT_POPUP_INDEX',
    ACCEPT_TERMS: 'ACCEPT_TERMS',
    ACCEPTED_PASSWORD_BY_POLITICS: 'ACCEPTED_PASSWORD_BY_POLITICS',
    CHECK_PASSWORD_ACCEPTED_BY_POLITICS: 'CHECK_PASSWORD_ACCEPTED_BY_POLITICS',
    FETCH_ASSOCIATED_ACCOUNTS: 'FETCH_ASSOCIATED_ACCOUNTS',

    fetchUserFailed: error => ({
        type: userActions.FETCH_USER_FAILED,
        payload: error
    }),

    fetchUserSuccess: (userId, data) => ({
        type: userActions.FETCH_USER_SUCCESS,
        payload: {
            data,
            userId
        }
    }),

    fetchUserPending: userId => ({
        type: userActions.FETCH_USER_PENDING,
        payload: {
            userId
        }
    }),

    loadUser: data => ({
        type: userActions.LOAD_USER,
        payload: {
            data,
            userId: 'login'
        }
    }),

    getProfile: () => ({
        type: userActions.GET_PROFILE,
        payload: {
            userId: 'check_validation'
        }
    }),

    logout: data => ({
        type: userActions.LOGOUT,
        payload: {
            userId: 'logout',
            data
        }
    }),

    savePattern: data => ({
        type: userActions.SAVE_PATTERN,
        payload: {
            data
        }
    }),

    currentUser: data => ({
        type: userActions.CURRENT_USER,
        payload: {
            data
        }
    }),

    nextPopupIndex: () => ({
        type: userActions.NEXT_POPUP_INDEX,
    }),

    acceptTerms: () => ({
        type: userActions.ACCEPT_TERMS,
    }),

    acceptedPasswordByPolitics: () => ({
        type: userActions.ACCEPTED_PASSWORD_BY_POLITICS
    }),

    checkPasswordAcceptedByPolitics: (password) => ({
        type: userActions.CHECK_PASSWORD_ACCEPTED_BY_POLITICS,
        payload: {
            password
        }
    }),

    fetchAssociatedAccounts: (email) => ({
        type: userActions.FETCH_ASSOCIATED_ACCOUNTS,
        payload: {
            email
        }
    })
};


export const userRequestActions = {
    failed: userActions.fetchUserFailed,
    success: userActions.fetchUserSuccess,
    pending: userActions.fetchUserPending
};
