import { api } from './api';

const UserSession = (() => {
  let authenticated = false;
  let checked = false;

  const getCookie = (name) => {
    const cookieName = name + '=';
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return null;
  }

  const setCookie = (name, value, days) => {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = '; expires=' + date.toUTCString();
    }

    document.cookie = name + '=' + value + expires + '; path=/; Secure;';
  }

  const deleteCookie = (name) => {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Secure;';
  }

  const getChecked = () => {
    return checked;
  }

  const getAuthenticated = () => {
    return getCookie('status_authenticated') === '1' || false;
  }

  const setChecked = (result) => {
    checked = result;
    setCookie('status_checked', result ? '1' : '0');
  }

  const setAuthenticated = (result) => {
    authenticated = result;
    setCookie('status_authenticated', result ? '1' : '0');
  }

  const getRememberUser = () => {
    return getCookie('user_remember');
  }

  const setRememberUser = (credentials) => {
    setCookie('user_remember', JSON.stringify(credentials));
  }

  const deleteRememberUser = () => {
    deleteCookie('user_remember');
  }

  const deleteSession = () => {
    setAuthenticated(0);
  }

  const validateSession = () => {
    if (getChecked()) {
      return true;
    }

    if (!getAuthenticated()) {
      setChecked(1);
      return false;
    }

    return api.fetchUser().then(response => {
      setChecked(1);
      if (response.success) {
        setAuthenticated(1);
      } else {
        setAuthenticated(0);
        deleteSession();
      }
      return response.success;
    });
  }

  return {
    getRememberUser,
    setRememberUser,
    deleteRememberUser,
    deleteSession,
    validateSession,
    getChecked,
    setAuthenticated,
    getAuthenticated
  };

})();

export default UserSession;