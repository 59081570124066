import { call, put } from 'redux-saga/effects';

import { userRequestActions } from '../users';
import { questionsListRequestActions } from '../questions-list';
import { assessmentsListRequestActions } from '../assessments-list';
import { assessmentRequestActions } from '../assessment-item';
import { teamsListRequestActions } from '../teams-list';
import { teamRequestActions } from '../teams';
import { membersListRequestActions } from '../members-list';
import { teamsMembersListRequestActions } from '../teams-members-list';
import { systemRequestActions } from '../system';
import { teamsReportListRequestActions } from '../teams-report';
import { appActions } from '../app';
import { teamsFullReportListRequestActions } from '../teams-full-report';
import { mindPatternsDataRequestActions } from '../mind-patterns';
import { registerRequestActions } from '../register/actions';
import { verifyCodeRequestActions } from '../sesion-verify/actions';
import { mindPatternsStoreOptionsRequestActions } from '../mind-patterns-storeOptions/actions';
import { mindPatternsStoreMPRequestActions } from '../mind-patterns-storemp/actions';
import { systemCheckRequestActions } from '../systemCheck/actions';
import { api } from './api-service';
import { forgotPasswordRequestActions } from '../forgotPassword/actions';
import { contactRequestActions } from '../contact/actions';
import { fullReportMpListRequestActions } from '../full-report-mp/actions';
import { userResumeRequestActions } from '../thinking-talents-user-resume/actions';
import { questionnaireResumeRequestActions } from '../questionnaire-resume/actions';
import { questionnaireItemsRequestActions } from '../questionnaire-items/actions';
import { questionnaireStoreOptionsRequestActions } from '../questionnaire-storeOptions/actions';
import { videosRequestActions } from '../videos/actions';
import { pdfsRequestActions } from '../pdfs/actions';
import { teamResumeRequestActions } from '../team-resume/actions';
import { profileRequestActions } from '../profile/actions';
import { communityRequestActions } from '../community-search/actions';
import { trackingRequestActions } from '../tracking/actions';
import { userDocumentsRequestActions } from '../user-documents/actions';

function* fetchEntities(apiFunction, actions, id, param) {

  try {
    yield put(actions.pending(id));

    const data = yield call(apiFunction, param || id);
    // console.log('fetchEntities',data)
    if (data.message === "Unauthenticated.") {
    //   yield put(appActions.navigateToPath("welcome"))
    }
    if ( data.success ){
      yield put(actions.success(id, data.data));
    }else
      yield put(actions.failed(data, id));

  }
  catch (error) {
    console.log('fetchEntities-error', error)
    yield put(actions.failed(error));
  }
}

export const fetchLogin = fetchEntities.bind(null, api.fetchLogin, userRequestActions);
export const fetchQuestions = fetchEntities.bind(null, api.fetchQuestions, questionsListRequestActions);
export const storeAssessmen = fetchEntities.bind(null, api.storeAssessmen, assessmentsListRequestActions);
export const fetchAssessment = fetchEntities.bind(null, api.fetchAssessment, assessmentsListRequestActions);
export const createAssessment = fetchEntities.bind(null, api.createAssessment, assessmentsListRequestActions);
export const fetchTeams = fetchEntities.bind(null, api.fetchTeams, teamsListRequestActions);
export const createTeam = fetchEntities.bind(null, api.createTeam, teamRequestActions);
export const updateTeam = fetchEntities.bind(null, api.updateTeam, teamRequestActions);
export const findMembers = fetchEntities.bind(null, api.findMembers, membersListRequestActions);
export const searchMembers = fetchEntities.bind(null, api.searchMembers, membersListRequestActions);
export const addMembersTeam = fetchEntities.bind(null, api.addMembersTeam, teamRequestActions);
export const removeMemberTeam = fetchEntities.bind(null, api.removeMemberTeam, teamRequestActions);
export const setLeaderMemberTeam = fetchEntities.bind(null, api.setLeaderMemberTeam, teamRequestActions);
export const fetchMembersTeam = fetchEntities.bind(null, api.loadMembersTeam, teamsMembersListRequestActions);
export const fetchSystem = fetchEntities.bind(null, api.fetchSystem, systemRequestActions);
export const deleteTeam = fetchEntities.bind(null, api.deleteTeam, teamRequestActions);
export const fetchReportTeam = fetchEntities.bind(null, api.fetchReportTeam, teamsReportListRequestActions);
export const fetchUser = fetchEntities.bind(null, api.fetchUser, userRequestActions);
export const fetchFullReportTeam = fetchEntities.bind(null, api.fetchFullReportTeam, teamsFullReportListRequestActions);
export const fetchMindPatters = fetchEntities.bind(null, api.fetchMindPatters, mindPatternsDataRequestActions);
export const fetchRegister = fetchEntities.bind(null, api.fetchRegister, registerRequestActions);
export const sesionVerify = fetchEntities.bind(null, api.sesionVerify, verifyCodeRequestActions);
export const fetchLogout = fetchEntities.bind(null, api.fetchLogout, userRequestActions);
export const fetchMindPattersStoreOptions = fetchEntities.bind(null, api.fetchMindPattersStoreOptions, mindPatternsStoreOptionsRequestActions);
export const fetchMindPattersStoreMP = fetchEntities.bind(null, api.fetchMindPattersStoreMP, mindPatternsStoreMPRequestActions);
export const fetchSystemCheck = fetchEntities.bind(null, api.fetchSystemCheck, systemCheckRequestActions);
export const fetchForgotPassword = fetchEntities.bind(null, api.fetchForgotPassword, forgotPasswordRequestActions);
export const fetchChangePassword = fetchEntities.bind(null, api.fetchChangePassword, forgotPasswordRequestActions);
export const fetchContact = fetchEntities.bind(null, api.fetchContact, contactRequestActions);
export const fetchPrivacy = fetchEntities.bind(null, api.fetchPrivacy, contactRequestActions);
export const fetchCurrentUser = fetchEntities.bind(null, api.fetchCurrentUser, userRequestActions);
export const fetchFullReportMP = fetchEntities.bind(null, api.fetchFullReportMP, fullReportMpListRequestActions);
export const fetchUserResume = fetchEntities.bind(null, api.fetchUserResume, userResumeRequestActions);
export const fetchQuestionnaireResume = fetchEntities.bind(null, api.fetchQuestionnaireResume,questionnaireResumeRequestActions);
export const fetchQuestionnaireItems = fetchEntities.bind(null, api.fetchQuestionnaireItems, questionnaireItemsRequestActions);
export const fetchQuestionnaireStoreAnswerOptions = fetchEntities.bind(null, api.fetchQuestionnaireStoreAnswerOptions, questionnaireStoreOptionsRequestActions);
export const fetchVideos = fetchEntities.bind(null,api.fetchVideos, videosRequestActions);
export const fetchVideosLogin = fetchEntities.bind(null,api.fetchVideosLogin, videosRequestActions);
export const fetchTeamResume = fetchEntities.bind(null,api.fetchTeamResume, teamResumeRequestActions);
export const fetchFullReportTTPDF = fetchEntities.bind(null,api.fetchFullReportTTPDF, pdfsRequestActions);
export const fetchFullReportMPPDF = fetchEntities.bind(null,api.fetchFullReportMPPDF, pdfsRequestActions);
export const fetchCareerCanvasReportPDF = fetchEntities.bind(null,api.fetchCareerCanvasReportPDF, pdfsRequestActions);
export const updateProfile = fetchEntities.bind(null,api.updateProfile, profileRequestActions);
export const updateProfilePicture = fetchEntities.bind(null,api.updateProfilePicture, profileRequestActions);
export const updateProfileStory = fetchEntities.bind(null,api.updateProfileStory, profileRequestActions);
export const fetchCommunityMembers = fetchEntities.bind(null, api.fetchCommunityMembers, communityRequestActions);
export const fetchCommunityMembersByFilters = fetchEntities.bind(null, api.fetchCommunityMembersByFilters, communityRequestActions);
export const setAcceptTerms = fetchEntities.bind(null,api.setAcceptTerms, userRequestActions);
export const saveTrackingEvent = fetchEntities.bind(null,api.saveTrackingEvent, trackingRequestActions);
export const checkPasswordAcceptedByPolitics = fetchEntities.bind(null,api.checkPasswordAcceptedByPolitics, userRequestActions);
export const fetchUserDocuments = fetchEntities.bind(null,api.fetchUserDocuments, userDocumentsRequestActions)
export const fetchUserDocumentById = fetchEntities.bind(null,api.fetchUserDocumentById, userDocumentsRequestActions)
export const setDocumentDownloaded = fetchEntities.bind(null,api.setDocumentDownloaded, userDocumentsRequestActions)
export const fetchAssociatedAccounts = fetchEntities.bind(null, api.fetchAssociatedAccounts, userRequestActions);