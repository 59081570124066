import React, { useEffect, useState } from 'react';
import { Button, Container, Form, Input, Label, Col, Row, FormGroup, FormText } from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { connect } from 'react-redux';
import { forgotPasswordActions } from '../../../../core/forgotPassword/actions';
import { useHistory } from 'react-router-dom';
import { ErrorPasswordPolitics } from '../../../components/common/ErrorPasswordPolitics';
import { passwordStrengthChecker } from '../../../../core/helper';

const animationClass = {
    appearActive: 'animated slideInRight',
    enter: 'animated',
    enterActive: 'animated slideInRight',
    exit: 'animated',
    exitActive: 'animated slideOutLeft',
}

export const ResetPassword = ({ forgotPassword, changePassword }) => {
    const history = useHistory();
    const [disable, setDisabled] = useState(true);
    const [inputValues, setInputValues] = useState({
        password: '',
        confirm_password: '',
        code: ''
    })
    const [formErrors, setformErrors] = useState({
        password: '',
        confirm_password: '',
        code: ''
    });
    const strength = passwordStrengthChecker(inputValues.password)

    console.log(forgotPassword.data && forgotPassword.data.email);

    useEffect(() => {
        setDisabled(!formValid());
        forgotPassword.success && forgotPassword.reset && history.push('/reset-password-done');
    }, [inputValues, forgotPassword])

    useEffect(() => {
        !forgotPassword.success && !forgotPassword.isPending && forgotPassword.message === "Verification Code not valid" ? formErrors.code = 'Code not valid' : formErrors.code = '';
        setDisabled(!formValid());
    }, [forgotPassword])

    useEffect(() => {
        if (!forgotPassword.success && forgotPassword.error_description && forgotPassword.error_description.password_politics) {

        }
    }, [forgotPassword])

    const handleInputChange = async ({ target }) => {
        const { name, value } = target;

        switch (name) {
            case "password":
                formErrors.password =
                    value.length < 8 ? "Minimum 8 characters required" : "";
                break;
            case "confirm_password":
                let matchPass = inputValues.password === value ? '' : 'No match Password';
                formErrors.confirm_password = value.length < 8 ? "Minimum 8 characters required" : matchPass;
                break;
            case "code":
                formErrors.code =
                    value.length < 3 ? "minimum 3 characaters required" : "";
                break;
            default:
                break;
        }

        setInputValues({
            ...inputValues,
            [name]: value
        });
    }

    const formValid = () => {
        let valid = true;

        // validate form errors being empty
        Object.values(formErrors).forEach(val => {
            val.length > 0 && (valid = false);
        });

        // validate the form was filled out
        Object.values(inputValues).forEach(val => {
            val === '' && (valid = false);
        });

        if(strength === 'Too weak!') valid = false

        return valid;
    };

    const onSubmitChagePWD = (e) => {
        e.preventDefault();
        let newPassword = {
            ...inputValues,
            id: forgotPassword.data.id
        }
        changePassword(newPassword)
    }

    return (
        <>
            <div className='view overflow-hidden bg-white'>
                <TransitionGroup className="content">
                    <CSSTransition classNames={animationClass} timeout={{ enter: 1000, exit: 1000 }} key='confirm' appear >
                        <div className="register-confirm-page">
                            <Container>
                                <div className="px-2 px-md-0 my-md-4">
                                    <div className="content-view">
                                        <Row>
                                            <Col sm={{ size: 6, offset: 3 }}>
                                                <h2 className="pl-3 pl-md-0">Reset Password</h2>
                                            </Col>
                                        </Row>
                                        <Form
                                            onSubmit={onSubmitChagePWD}
                                            className="form-login p-4 p-md-0 shadow rounded-lg bg-white"
                                        >
                                            <Row>
                                                <Col sm={{ size: 6, offset: 3 }}>
                                                    <FormGroup>
                                                        <Label for="password">New password</Label>
                                                        <Input
                                                            type="password"
                                                            name="password"
                                                            id="password"
                                                            placeholder="Please enter your password"
                                                            onChange={handleInputChange}
                                                            value={inputValues.password}
                                                        />
                                                        <FormText>{formErrors.password}</FormText>
                                                        {inputValues.password && !formErrors.password && 
                                                            <FormText>{ strength }</FormText>
                                                        }

                                                        <Label for="pwd">Confirm Password</Label>
                                                        <Input
                                                            type="password"
                                                            name="confirm_password"
                                                            id="pwd"
                                                            placeholder="confirm your Password"
                                                            onChange={handleInputChange}
                                                            value={inputValues.confirm_password}
                                                        />
                                                        <FormText>{formErrors.confirm_password}</FormText>

                                                        <Label for="code">
                                                            <div className="d-block">
                                                                <div>
                                                                    The verification code was sent to {forgotPassword.data && forgotPassword.data.email}.
                                                                </div>
                                                                <div>
                                                                    Please check your email and enter the code here:
                                                                </div>
                                                                <div style={{ fontSize: "0.66rem" }}>
                                                                    If you do not receive this information in your inbox within 5 minutes or less, please check your spam folder or contact us <a target="_blank" href="/front/contact-us">here.</a>
                                                                </div>
                                                            </div>
                                                        </Label>

                                                        <Input
                                                            type="text"
                                                            name="code"
                                                            id="code"
                                                            placeholder="Please enter your verification code"
                                                            onChange={handleInputChange}
                                                            value={inputValues.code}
                                                        />
                                                        <FormText>{formErrors.code}</FormText>

                                                    </FormGroup>
                                                    <Button
                                                        color="primary"
                                                        type="submit"
                                                        className="text-uppercase rounded-pill px-md-5 btn-block"
                                                        disabled={disable}
                                                    >
                                                        Change Password
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </div>
                            </Container>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </div>
        </>
    )
}

const mapStateToProps = (state, props) => {
    const { forgotPassword } = state;
    return {
        forgotPassword
    };
};

const mapDispatchToProps = {
    changePassword: forgotPasswordActions.changePassword
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPassword);
