import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Card } from 'reactstrap';
import intro_mp from '../../../../assets/img/Mind-patterns.svg';

export const MindPatternsHome = ({ user, oneColumn }) => {

  const { systemCheck } = useSelector(state => state);
  const account = systemCheck.data.data;


  let mind_pattern_data = null;
  if (user.mind_pattern) {
    mind_pattern_data = user.mind_pattern.split("").map((mp, k) =>
      <div key={`${k}_${mp}`} className={`d-inline-block rounded-circle mx-1 mind-pattern mind-pattern-${mp.toLowerCase()}`}>
        <span>{mp}</span>
      </div>
    )
  }
//   console.log(user.assessment_access)
//   console.log(!user.talents)

  
  return (
    <Col xl='12' className='mt-3'>
      <Card className="p-4 pt-md-1 mb-4 mb-xl-0 ">
        <div className={`${oneColumn ? "px-md-5": ''}`}>

          <div className="encabezado encabezado-icono">
            <div className="mb-0 pt-2 pb-1 d-flex align-items-end">
              <img src={intro_mp} alt="Mind Patterns" width="40" height="auto" />
              <h6 className="mb-0 ml-3 title-underline">Mind Patterns</h6>
            </div>
            <span className="border border-info d-none d-md-block"></span>
          </div>

          {!user.mind_pattern &&
            (<>
              <p className="my-4 mt-md-2">
                {
                  user.assessment_access === 2 && !user.talents ?
                    "The MyTrueAdvance is designed for you to access the discoveries sequentially. Please complete the Thinking Talents discovery first, then you can access the Mind Pattern discovery" :
                    "Discover what your brain needs to excel at learning and communication."
                }
              </p>
            </>)
          }


          {user.mind_pattern &&
            (<>
              <p className="my-4 mt-md-2">My Mind Pattern.</p>
              {mind_pattern_data && <div className="mind-patterns-content d-flex justify-content-md-center justify-content-sm-start ml-n1 mb-3 ">{mind_pattern_data}</div>}
            </>)
          }
        </div>

        {user.assessment_access === 2 && !user.talents ? null :
          <Link
            to='/mind-patterns'
            className="btn btn-outline-primary text-uppercase rounded-pill d-inline-flex px-md-4 mx-auto"
          >
            {
              user.mind_pattern ? (<><i className="icon-view-report mr-2"></i>View Report</>) : 'Let’s start!'
            }
          </Link>
        }
      </Card>
    </Col>
  )
}
const mapStateToProps = (state, props) => {
  const { user } = state;
  return {
    user
  };
};

const mapDispatchToProps = {
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MindPatternsHome);
