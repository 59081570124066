import { mindPatternsStoreMPActions } from '../mind-patterns-storemp/actions';
import { profileActions } from '../profile/actions';
import { userActions } from './actions';
import { User } from './user';

export const userReducer = (state = new User(), { payload, type }) => {
  switch (type) {
    case userActions.FETCH_USER_FAILED:
        payload.description = payload.response && payload.response.data && payload.response.data.message
      return state.merge({
        isPending: false,
        error: payload,
        id: null,
      });
    case userActions.FETCH_USER_SUCCESS:
      const { data } = payload;
      return state.withMutations(user => {
        user.merge({
          isPending: false,
          id: data === "Logged out" ? null : ( payload.data.id || user.id ),
          error: null,
          avatar: payload.data.avatar || user.avatar,
          email: payload.data.email || user.email,
          first_name: payload.data.first_name || user.first_name,
          token_id: payload.data.token_id || user.token_id,
          last_name: payload.data.last_name || user.last_name,
          account: payload.data.account || user.account,
          is_manager: payload.data.is_manager || user.is_manager,
          seniority_id: payload.data.seniority_id || user.seniority_id,
          seniority: payload.data.seniority || user.seniority,
          mind_pattern: payload.data.mind_pattern || user.mind_pattern,
          talents: payload.data.talents || user.talents,
          documents: payload.data.documents || user.documents,
          access_pdf_tt: payload.data.access_pdf_tt || user.access_pdf_tt,
          access_pdf_mp: payload.data.access_pdf_mp || user.access_pdf_mp,
          accept_terms: payload.data.accept_terms || user.accept_terms,
          must_change_pwd: payload.data.must_change_pwd || user.must_change_pwd,
          pwd_politics_errors: payload.data.pwd_politics_errors || user.pwd_politics_errors,
          profile_story: payload.data.profile_story || user.profile_story
        })
      });
    case userActions.FETCH_USER_PENDING:
      return state.set('isPending', true);
    case userActions.GET_PROFILE:
      return state.set('checked', true);
    case userActions.LOAD_USER:
      return state.set('email', payload.data.email);
    case userActions.NEXT_POPUP_INDEX:
      return state.set('last_popup_index', state.last_popup_index+1);

    case mindPatternsStoreMPActions.FETCH_MINDPATTERNS_STOREMP_SUCCESS:
      return state.withMutations(user => {
        user.merge({
          isPending: false,
          id: payload.data.id,
          avatar: payload.data.avatar,
          email: payload.data.email,
          first_name: payload.data.first_name,
          last_name: payload.data.last_name,
          account: payload.data.account,
          is_manager: payload.data.is_manager,
          seniority_id: payload.data.seniority_id,
          mind_pattern: payload.data.mind_pattern,
          talents: payload.data.talents,
        });
      });
    default:
      return state;
  }
}
